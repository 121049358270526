<template>
  <v-container class="full-height">
    <v-row class="full-height" align="center">
      <v-col cols="12">
        <v-card max-width="450" class="mx-auto box-shadow" outlined>
          <v-card-title class="font-weight-bold justify-center secondary white--text">
            <v-icon left dark>mdi-lock-outline</v-icon>
            <span>Reset Your Password</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-7">
            <v-alert v-if="!$route.query.oobCode" type="warning" colored-border border="left" class="box-shadow">
              No reset code provided.
            </v-alert>

            <v-alert v-if="submitted" type="success" colored-border border="left">
              Your password was successfully updated. Go back to <router-link :to="{ name: 'Login' }">login</router-link> page.
            </v-alert> 

            <template v-else-if="$route.query.oobCode && !submitted">
              <v-fade-transition>
                <v-alert v-if="status.error" type="error" border="left" class="mb-7">
                  {{ status.error }}
                </v-alert>
              </v-fade-transition>

              <v-alert v-if="status.resetSent" type="success" colored-border border="left">
                {{ status.resetSent }}
              </v-alert>

              <v-form v-else ref="resetForm" @submit.prevent="validateForm()">

                <v-text-field
                  v-model="password"
                  @click:append="showPassword = !showPassword"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[rules.required]"
                  label="New password"
                  outlined
                  dense
                ></v-text-field>

                <v-btn
                  @click="validateForm()"
                  :loading="status.updating"
                  color="primary white--text"
                  depressed
                  block
                  large
                >Submit</v-btn>

                <div class="mt-3 text-center">
                  <div>
                    <router-link :to="{ name: 'Login' }">Back to login page</router-link>
                  </div>
                </div>

              </v-form>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapState } from 'vuex'
import rules from '@/rules'

export default {
  name: 'ResetPassword',

  metaInfo: {
    title: 'Reset Password'
  },

  data() {
    return {
      rules,
      password: null,
      submitted: false,
      showPassword: false,
    }
  },

  computed: {
    ...mapState({
      status: state => state.register.status,
    })
  },

  methods: {
    validateForm() {
      if (this.$refs.resetForm.validate()) {
        Promise.resolve(
          this.$store.dispatch('register/confirmResetPassword', {
            password: this.password,
            code: this.$route.query.oobCode
          })
        )
        .then(() => {
          this.submitted = true
        }, (error) => {
          console.log(error.message)
        })
      }
    }
  },

  created() {
    this.$store.commit('login/resetState')
  }
}

</script>